import React from "react"
import FotoCulqui from "@components/pageCasosdeExito/image/imgPhotoEmpresa/gualda/FotoGualda.png"
import FotoCulqui2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/gualda/FotoGualda2.png"

//logo de empresas clientes
import Gualda from "@components/pageCasosdeExito/image/logoscustomer/Gualda.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import livechat from "@components/pageCasosdeExito/image/iconcards/livechat.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/gualda/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/gualda/img2.svg"

const text1 = (
  <span>
    Gualda Training, es una academia digital avalada internacionalmente, que ha
    sido elegida por más de 13mil estudiantes en Latinoamérica para ampliar sus
    conocimientos, actualizarse o dar sus primeros pasos en el mundo fitness.
    <br /> <br />
    Debido a su vertiginoso crecimiento, la empresa necesitó escalar sus
    comunicaciones por WhatsApp. Y lo logró con Beex. Actualmente, ha mejorado
    en la prospección de sus leads, logrando reducir sus tiempos de respuestas
    en hasta 88% comparados con periodos anteriores.
  </span>
)

const text2 = (
  <span>
    Gualda Training, es una empresa argentina que tiene como misión transformar
    personas en profesionales del fitness, a través de innovación educativa y un
    continuo acompañamiento docente con su equipo de profesionales.
    <br />
    <br />
    El objetivo principal que tienen como marca, es aportar innovación al mundo
    del fitness y la educación, brindando capacitaciones digitales con avales
    internacionales y accesibles para todo tipo de público, sin necesidad de
    poseer conocimientos previos.
    <br />
    <br />
    La empresa, con presencia en la mayoría de países de habla hispana, ha
    tenido un crecimiento acelerado debido a la novedosa propuesta de valor con
    la que cuentan. Considerando esto, la cantidad de interacciones recibidas a
    diario ha crecido exponencialmente, siendo su canal principal WhatsApp.
    <br />
    <br />
    La empresa, que ya aplicaba a procesos ágiles para sus operaciones, ya
    contaba con un CRM interno de desarrollo propio, por lo que sabía con
    exactitud cuál era uno de los objetivos principales del proveedor omnicanal
    que elegirían, y era que sean 100% integrables con otras plataformas y
    puedan crearse automatizaciones sin mayores esfuerzos.
    <br />
    <br />Y es por ello que en 2021, luego de su benchmarking de plataformas,
    decidieron ir con Beex. Logrando
    solicitar y aplicar al API de WhatsApp Business en tan solo días.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “La centralización de mensajes que que nos brinda Beex, nos ha permitido
    escalar en nuestro proceso de ventas mientras se mantiene la calidad en la
    atención y el servicio, especialmente en nuestro proyecto, donde la
    experiencia de usuario es nuestra máximo prioridad.”
  </p>
)

const text4 = (
  <span>
    Axel Gualda, founder y CEO de la empresa, se ha involucrado desde el inicio
    con la implementación de la solución. Desde la elección hasta la puesta en
    marcha acorde a la necesidad de la marca.
    <br />
    <br />
    Como nos cuenta Axel, sus procesos de atención al cliente y ventas han
    mejorado notablemente con una solución integral de WhatsApp que contempla
    funcionalidades robustas, como son: Integraciones, automatizaciones y
    opciones nativas de comunicación como gestión multiagente, enrutamiento
    inteligente de casos, supervisión de interacciones en tiempo real,
    plantillas automatizadas y más.
  </span>
)

const text5 = (
  <span>
    Debido a la gran cantidad de consultas diarias que tiene Gualda Training en
    su canal de WhatsApp, de diferentes países en Latinoamérica, era necesario
    implementar un chatbot de atención al cliente que pueda brindar información
    en distintos rangos horarios.
    <br />
    <br />
    Por ello, a través del módulo dinámico de chatbots, se
    implementaron diferentes chatbots por diferentes casos de uso, teniendo en
    cuenta los horarios más frecuentados por los usuarios de Gualda Training.
    <br />
    <br />
    Como menciona Axel, la interacción con los flujos de los chatbots de Beex
    no son complejos, por el contrario, se le ha hecho simple la creación de
    estos, gracias a que la herramienta es simple, rápida y eficaz.
  </span>
)
const text6 = (
  <span>
    “Los chatbots de Beex son una gran herramienta para estar activos y para
    holdear tickets hasta que un agente esté disponible. Con su implementación,
    nuestros leads pueden recibir información de nuestros cursos y
    capacitaciones, lo que ha hecho que nuestra tasa de conversión haya mejorado
    en 27%.”
  </span>
)

const text7 = (
  <span>
    Gualda Training cuenta con una operación numerosa de agentes de atención al
    cliente y comerciales. Por este motivo, la implementación del API de
    WhatsApp Business fue fundamental para tener un canal certificado único que
    contemple la creación y gestión de múltiples usuarios.
    <br /> <br />
    Es por ello, que gracias a esta funcionalidad de Beex, como nos cuenta
    Axel, el poder trabajar con múltiples agentes y darle la continuidad a las
    conversaciones independientemente del interlocutor es fundamental.
    <br /> <br />
    Entre otras cosas ganadas con la gestión multiagente por WhatsApp, sin duda
    el poder atender oportunamente a todos sus leads o clientes ha sido
    importante, ya que han logrado reducir su tiempo de primera respuesta.
  </span>
)

const text10 = (
  <span>
    “Hemos disminuido notablemente nuestro tiempo de primera respuesta para
    nuestras atenciones, así como su enrutamiento de casos. Ahora ya no tenemos
    el conflicto generado por tener múltiples números de WhatsApp, lo que
    impedía la trazabilidad de nuestras interacciones.”
  </span>
)

const text11 = (
  <span>
    Algo que siempre mencionamos en Beex, es el acompañamiento total que
    brindamos a nuestros clientes respecto a las soluciones compartidas, y esto
    lo ha evidenciado de forma notable Gualda Training.
    <br /> <br />
    No solo respecto a consultas o casos de atención, sino para requerimientos o
    personalizaciones que la marca ha necesitado y que el equipo de producto ha
    sabido atender con creces.
    <br /> <br />
    Es por ello, que a palabras de Axel, se encuentran más que satisfechos con
    la atención brindada por Beex Service Desk. Tanto así, que consideran
    el acompañamiento del equipo hacia su operación como una de sus mayores
    virtudes, ya que siempre se encuentran activos para dar respuesta y atender
    sus solicitudes.
  </span>
)

const text12 = (
  <span>
    “Estamos más que conformes con el soporte de Beex. Siempre que
    necesitamos de su asistencia para resolver alguna contingencia, tuvieron una
    velocidad de respuesta y capacidad de resolución impecable. Además, hemos
    podido sumar mejoras acorde a nuestra necesidad.”
  </span>
)

const text13 = (
  <p>
    Estos son algunos procesos con los ayudamos 
    a Gualda Training en su operación de atención al cliente y ventas:
  </p>
)
const text14 = (
  <p>
    Estamos súper felices de poder brindarles nuestras soluciones a
    una empresa como Gualda Training, con la que compartimos una cultura muy
    parecida bajo un marco ágil que potencia nuestras operaciones. El feedback
    ganado mutuamente es algo que valoramos a todo nivel.
  </p>
)

const data = {
  intro: {
    image: Gualda,
    link: "#",
    classlogo: "logo__clientes--casa",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoCulqui,
    text4: text4,
    title5: "Chatbots en WhatsApp",
    text5: text5,
    text6: text6,
    title7: "WhatsApp multiagente",
    text7: text7,
    fototwo: FotoCulqui2,
    text10: text10,
    title11: "Soporte integral",
    text11: text11,
    text12: text12,
    text13: text13,
    text14: text14,

    metric1: metrica1,
    metric2: metrica2,
  },

  cards: [
    {
      title: "whatsapp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para sus operaciones de atención al cliente y ventas. Beex logra que sus clientes conversen con Gualda Training mediante la App más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más.",
    },
    {
      title: "Chatbots",
      img: chatbots,
      info:
        "Implementación de chatbots por los canales de más interacción con sus clientes, como WhatsApp. Diseño y creación por rangos horarios para compartir respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
    {
      title: "Gestión multiagente:",
      img: livechat,
      info:
        "Creación y administración de múltiples usuarios para gestionar bajo una sola cuenta de WhatsApp. Gestión de colas de atención asignadas a chatbots con enrutamiento inteligente de casos.",
    },
  ],
}

export default data
